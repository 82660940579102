<template>
	<div class="card">
		<div class="card-body">
			<apexchart
				ref="chart"
				class="apex-charts"
				type="bar"
				height="380"
				:series="series"
				:options="chartOptions"
			></apexchart>
		</div>
	</div>
</template>

<script>
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	export default {
		data() {
			return {
				series: [],
				chartOptions: {
					chart: {
						toolbar: {
							show: false,
						},
					},
					plotOptions: {
						bar: {
							horizontal: true,
						},
					},
					dataLabels: {
						enabled: false,
					},
					xaxis: {
						categories: [],

						labels: {
							formatter: (value) => formatter.format(value),
						},
					},
					colors: [
						function ({ value, seriesIndex, w }) {
							var letters = "0123456789ABCDEF";
							var color = "#";
							for (var i = 0; i < 6; i++) {
								color += letters[Math.floor(Math.random() * 16)];
							}
							return color;
						},
					],
					yaxis: {
						show: false,
					},
					tooltip: {
						y: {
							formatter: (value) => formatter.format(value),
							title: { formatter: (seriesName) => seriesName },
						},
					},
					states: {
						hover: {
							filter: "none",
						},
					},
					grid: {
						borderColor: "#f1f3fa",
					},
				},
			};
		},

		methods: {
			render(labelList, data) {
				this.series = [
					{
						name: data.name,
						data: [...data.values],
					},
				];
				this.$refs["chart"].updateOptions({
					xaxis: {
						categories: labelList,
					},

					yaxis: {
						show: labelList.length > 0 ? true : false,
					},
				});
			},
		},
	};

	// const formatter = new Intl.NumberFormat("en-US", {
	//     style: "currency",
	//     currency: "USD"
	// });

	// import {
	//     Bar
	// } from 'vue-chartjs'

	// export default {
	//     extends: Bar,

	//     methods: {
	//       render(labelList, dataArrs) {
	//         const datasets = [];
	//         for (let i = 0; i < dataArrs.length; i++) {
	//           datasets.push(
	//             {
	//               barThickness: 10,
	//               barPercentage: 0.7,
	//               categoryPercentage: 0.5,
	//               label: dataArrs[i].label,
	//               backgroundColor: dataArrs[i].color,
	//               borderColor: dataArrs[i].color,
	//               hoverBackgroundColor: dataArrs[i].color,
	//               hoverBorderColor: dataArrs[i].color,
	//               data: [dataArrs[i].data],
	//             }
	//           )
	//         }
	//         console.log(datasets);

	//         this.renderChart({
	//           labels: labelList,
	//           datasets: datasets,
	//         },
	//         {
	//           responsive: true,
	//           maintainAspectRatio: false,
	//           legend: {
	//             display: false,
	//           },
	//           tooltips: {
	//             callbacks: {
	//               label: function(t, d) {
	//                 var xLabel = d.datasets[t.datasetIndex].label;
	//                 var yLabel = formatter.format(t.yLabel);
	//                 return xLabel + ': ' + yLabel;
	//               }
	//             }
	//           },
	//           scales: {
	//             yAxes: [{
	//               gridLines: {
	//                   display: false,
	//               },
	//               stacked: false,
	//               ticks: {
	//                   callback: (label, index, labels) => {return formatter.format(label)}
	//                   // stepSize: 10000,
	//               },
	//             }, ],
	//             xAxes: [{
	//               stacked: false,
	//               gridLines: {
	//                   color: 'rgba(0,0,0,0.01)',
	//               },
	//             }, ],
	//           },
	//         }

	//         )
	//       }
	//     }
	// }
</script>
