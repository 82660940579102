<template>
	<Layout>
		<PageHeader :title="title" :items="items" />

		<div class="row">
			<div class="col-2">
				<label class="mt-3">Multiple Select</label>
				<multiselect
					v-model="selectedBranches"
					:options="multiSelectBranches"
					:multiple="true"
					:close-on-select="false"
					label="name"
					track-by="name"
					:searchable="true"
					:group-select="true"
					group-label="label"
					group-values="branches"
					@close="loadBranches"
					@input="loadBranches"
				></multiselect>
			</div>

			<div class="col-9">
				<div class="row mt-3">
					<div class="col-6">
						<div class="form-group mb-3">
							<label>Date Range</label>
							<br />
							<date-picker
								v-model="daterange"
								range
								append-to-body
								lang="en"
								confirm
								@input="loadBranches"
								format="YYYY-MM-DD"
								value-type="format"
							/>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<CustomBarChart ref="barGraph" />
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header";
	import CustomBarChart from "@/components/charts/customHorizontalBarGraph";
	import Multiselect from "vue-multiselect";
	import { mapState, mapActions } from "vuex";
	import DatePicker from "vue2-datepicker";

	/**
	 * Starter component
	 */
	export default {
		page: {
			title: "Compare Branches",
		},
		data() {
			return {
				title: "Compare Branches",
				items: [
					{
						text: "Reports",
					},
					{
						text: "Compare Branches",
						active: true,
					},
				],

				selectedBranches: [],
				daterange: "",
				dateFormat: {
					stringify: (date) => {
						return date ? new Date(date).toISOString().substr(0, 10) : "";
					},

					parse: (value) => {
						return value ? new Date(value).toISOString().substr(0, 10) : null;
					},
				},
			};
		},

		beforeMount() {
			if (this.branches.length < 1) this.getBranches();
		},

		components: {
			Layout,
			PageHeader,
			Multiselect,
			CustomBarChart,
			DatePicker,
		},

		computed: {
			...mapState("branchModule", ["branches"]),
			multiSelectBranches() {
				return [
					{
						label: "Select All",
						branches: this.branches,
					},
				];
			},
		},

		methods: {
			...mapActions("branchModule", ["getBranches"]),
			...mapActions("transactionsModule", ["getTransactionSummary"]),

			async loadBranches() {
				if (this.daterange.length > 0) {
					const transactionSummary = await this.getTransactionSummary({
						groupBy: "branch",
						startDate: this.daterange[0],
						endDate: this.daterange[1],
					});

					if (transactionSummary.status == 200) {
						if (Array.isArray(transactionSummary.data)) {
							this.chartColors = [];
							const labelList = [];
							const branchTransactions = [];
							const { data } = transactionSummary;
							const branchesToCheck = [...this.selectedBranches];

							//find the selected branches in the transactions pulled form the api
							for (let i = 0; i < data.length; i++) {
								let matchingIndex = "";
								const transaction = data[i];
								const matchingBranch = branchesToCheck.find((branch, index) => {
									matchingIndex = index;
									return branch._id == transaction.branch;
								});

								//pushes a new data point for the bar graph
								//and removes the branch that was matched rom the list of selected branches to check.
								if (matchingBranch) {
									branchTransactions.push(transaction.profits);
									labelList.push(matchingBranch.name);
									branchesToCheck.splice(matchingIndex, 1);
								}

								//checks to see if there are anymore branches left to check
								if (branchesToCheck.length == 0) {
									break;
								}
							}
							const graphData = {
								name: "Net Profits",
								values: branchTransactions,
							};
							this.$refs["barGraph"].render(labelList, graphData);
						}
					}
				}
			},

			getRandomColor() {
				var letters = "0123456789ABCDEF";
				var color = "#";
				for (var i = 0; i < 6; i++) {
					color += letters[Math.floor(Math.random() * 16)];
				}
				return color;
			},
		},
	};
</script>
